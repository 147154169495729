import styled from 'styled-components'
import { Box } from 'rebass/styled-components'
import { Link as GatsbyLink } from 'gatsby'
import GatsbyImage from 'gatsby-image'

export const HeaderLink = styled(GatsbyLink)`
  color: ${props => props.theme.colors.sidebar.link};
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
`

export const Title = styled.h2`
  font-family: ${props => props.theme.font.body};
  margin-bottom: 0rem !important;
`

export const PostInfo = styled.h3`
  font-family: ${props => props.theme.font.body};
  font-weight: 300 !important;
  font-size: 12px !important;
  display: inline-block;
  padding-right: 1rem;
  position: relative;
  top: -1.5rem;
  margin-bottom: -3rem !important;
`

export const PostDate = styled(PostInfo)`

`

export const ReadTime = styled(PostInfo)`

`

export const BodyText = styled.p`
`

export const BodyLink = styled(GatsbyLink)`
  color: ${props => props.theme.colors.sidebar.link} !important;
  font-family: ${props => props.theme.font.body};
  border: none !important;
  font-weight: 300;
`

export const ReadMoreLink = styled(GatsbyLink)`
  color: ${props => props.theme.colors.sidebar.link};
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
`

export const Img = styled(GatsbyImage)`
  border-top-left-radius: ${props => props.theme.content.borderRadius};
  border-top-right-radius: ${props => props.theme.content.borderRadius};
  margin: -3rem -6rem 1rem;

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin: -1rem;
  }
`
