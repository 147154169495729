import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { format } from 'date-fns'
import { Maybe, GatsbyImageSharpFluidFragment } from '../../graphqlTypes'
import ContentBox from '../ContentBox'
import Tags from '../Tags'
import { HeaderLink, BodyText, BodyLink, Img, Title, PostDate, ReadTime } from './styled'

interface Props {
  slug: string
  title: string
  excerpt?: string
  timeToRead: number
  tags?: Maybe<ReadonlyArray<Maybe<string>>>
  date?: Date
  headerImage?: {
    fluid?: Maybe<{ readonly fluid?: Maybe<GatsbyImageSharpFluidFragment> }>
    alt?: string
  }
}

const PostExcerpt: FunctionComponent<Props> = ({ slug, title, timeToRead, excerpt, tags, headerImage, date }: Props) => {
  const fluidImageProps = headerImage?.fluid as unknown as GatsbyImageFluidProps
  return (
    <ContentBox>
      { fluidImageProps !== undefined ? <Link to={`/posts/${slug}/`}><Img fluid={fluidImageProps} alt={headerImage?.alt} /></Link> : ''}
      <Title><HeaderLink to={`/posts/${slug}/`}>{title}</HeaderLink></Title>
      <PostDate>{format(new Date(date as Date), 'yyyy-MM-dd')}</PostDate><ReadTime>{timeToRead} minute read.</ReadTime>
      <BodyText>
        <BodyLink to={`/posts/${slug}/`} dangerouslySetInnerHTML={{ __html: excerpt.replace(/\n/g, '<br>') }} />
      </BodyText>
      <Tags tags={tags} />
    </ContentBox>
  )
}

export default PostExcerpt
