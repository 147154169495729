import styled from 'styled-components'
import {
  Box,
  Flex
} from 'rebass/styled-components'
import { Link as GatsbyLink } from 'gatsby'

export const Wrapper = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
`

export const Item = styled(Box)`
  border: thin solid ${props => props.theme.colors.sidebar.border};
  border-radius: ${props => props.theme.content.borderRadius};
  padding: 0.4rem;
  margin: 0 0.5rem 0 0;
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
  font-size: 1.2rem;

  :hover {
    border: thin solid ${props => props.theme.colors.lightText};
  }
`

export const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.lightText};
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
  transition: 0.2s all ease-in-out;

  :hover {
    color: ${props => props.theme.colors.text};
  }
`
