import React, { Fragment, FunctionComponent } from 'react'
import { Maybe } from '../../graphqlTypes'
import { Wrapper, Item, Link } from './styled'

interface Props {
  tags?: Maybe<ReadonlyArray<Maybe<string>>>
}

const Tags: FunctionComponent<Props> = ({ tags }: Props) => {
  return (
    <Fragment>
      <Wrapper>
        {tags.map((tag, i) => (
          <Item key={i}>
            <Link to={`/tags/${tag}/`}>{tag}</Link>
          </Item>
        ))}
      </Wrapper>
    </Fragment>
  )
}

export default Tags
